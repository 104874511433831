import { getQueryParams } from '@/utils-v2/helpers/getQueryParams.ts';

const deliveriesBaseUrl = `/${import.meta.env.VITE_GATEWAY_API_PREFIX}/v0/deliveries`;

function adapterTrackingPointData(response) {
  return {
    ...response,
    courier: {
      ...response.courier,
      contact_name: response.courier.name || response.courier.contact_name,
    },
  };
}

export function orders(client) {
  return {
    createOrder(payload) {
      return client.post(`${deliveriesBaseUrl}/groups`, payload);
    },
    fetchOrders(payload, signal = null) {
      payload.was_planned = payload.planned;
      delete payload.planned;
      const URL = `${deliveriesBaseUrl}/groups?${getQueryParams(payload)}`;

      return client.get(URL, { signal });
    },
    groupOrderById(id) {
      return client.get(`${deliveriesBaseUrl}/groups/${id}`);
    },
    cancelGroupOrder(groupId, payload) {
      return client.patch(`${deliveriesBaseUrl}/groups/${groupId}`, payload);
    },
    validateCoordinate(payload) {
      const data = {
        longitude: payload[0],
        latitude: payload[1],
      };

      return client.post(`${deliveriesBaseUrl}/address/validate-coordinate`, data);
    },
    fetchDeliveryTimeAndPrice(payload) {
      return client.post(`${deliveriesBaseUrl}/orders/tariffs`, payload);
    },
    fetchOptimizedTimeAndPrice(payload) {
      payload.optimize_trip = true;


      return client.post(`${deliveriesBaseUrl}/orders/tariffs`, payload);
    },
    fetchCancelTypes(type) {
      return client.get(`${deliveriesBaseUrl}/cancel-types?type=${type}`);
    },
    getPointData(uuid) {
      return client
        .get(`${deliveriesBaseUrl}/get-point-data/${uuid}`)
        .then(response => adapterTrackingPointData(response));
    },
    getOrdersByCourierId(courierId) {
      return client.get(`${deliveriesBaseUrl}/get-courier-data/${courierId}`);
    },
    kaspiBoxUploadOrders() {
      return client.post(`${deliveriesBaseUrl}/upload-orders/`);
    },
  };
}
